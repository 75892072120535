/* Notification colors */
/* Rating colors */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace; }

.absolute {
  position: absolute; }

.absolute-important {
  position: absolute !important; }

.relative {
  position: relative; }

.flex {
  display: flex; }

.flex.wrap {
  flex-wrap: wrap; }

.flex.column {
  flex-direction: column; }

.flex.grow {
  flex-grow: 1; }

.grow-0 {
  flex-grow: 0 !important; }

.justify-center {
  justify-content: center; }

.align-baseline {
  align-items: baseline !important; }

.space-between {
  justify-content: space-between; }

.space-around {
  justify-content: space-around; }

.my-purple {
  color: #4e54c8 !important; }

.bg-login {
  background: linear-gradient(90deg, white, #6e5ee2); }

.bg-violet {
  background: #4e54c8;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(90deg, #4739a9, #6e5ee2);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(90deg, #4739a9, #6e5ee2); }

.bg-violet-h {
  background: #4e54c8;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(180deg, #6e5ee2, #4739a9);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(180deg, #6e5ee2, #4739a9); }

.bg-pink-h {
  background: #ed689d;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(180deg, #edb9cd, #ed689d);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(180deg, #edb9cd, #ed689d); }

.max-weight {
  font-weight: 1000 !important; }

.bg-green {
  background: #71bd6b !important; }

.bg-white {
  background: white !important; }

.bg-blue {
  background: #001739 !important; }

.bg-blue.transparent {
  background: rgba(0, 23, 57, 0.94) !important; }

.bg-red {
  background: #f6414e !important; }

.color-red {
  color: #f6414e !important; }

.color-green {
  color: #21ba45 !important; }

.bg-yellow-flash {
  background: #f4d160 !important; }

.bg-yellow {
  background: #fae2bf !important; }

.bg-grey {
  background: #aeaeae !important; }

.bg-my-purple {
  background: #4e54c8 !important; }

.bg-my-purple.transparent {
  opacity: 0.2; }

.button.bg-my-purple {
  opacity: 0.8;
  color: white !important; }

.button.bg-my-purple:hover {
  opacity: 1; }

.color-white {
  color: white !important; }

.color-black {
  color: black !important; }

.color-blue {
  color: #001739 !important; }

.color-pink {
  color: #ed689d; }

.color-cyan {
  color: #50bac4; }

.underline {
  text-decoration: underline;
  text-underline-position: under; }

.underline-decoration-none {
  text-decoration: none !important; }

.transparent-underline {
  border-bottom: 1px solid transparent !important; }

.custom-underline {
  border-bottom: 1px solid !important; }

.game-cutom-underline {
  border-bottom: solid 2px !important; }

.underline-hover:hover {
  cursor: pointer;
  border-bottom: 1px solid !important; }

.super-title {
  font-size: 8rem !important;
  height: 8.5rem; }

.description-container {
  padding: 1em;
  background: rgba(78, 84, 200, 0.15);
  border-radius: 5px;
  color: black;
  font-size: small; }

.sub-legend {
  font-size: smaller;
  margin-top: 1.5em; }

.centered-container {
  display: flex !important;
  align-items: center;
  justify-content: center; }

.centered-container.column {
  flex-direction: column; }

.flex-direction-column {
  flex-direction: column; }

.centered-item {
  align-self: center; }

.centered-childs {
  align-items: center; }

.margin-auto {
  margin: auto !important; }

.margin-15 {
  margin: 15px; }

.margin-15-i {
  margin: 10px !important; }

.to-be-pointer {
  cursor: pointer;
  opacity: 0.7; }

.no-pointer {
  cursor: initial !important; }

.no-pointer:hover {
  cursor: initial !important; }

.to-be-pointer:hover {
  opacity: 1; }

.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 3s ease-in-out;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes dropdown {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes appears {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.background-color-1 {
  background: navajowhite; }

.color-1 {
  color: navajowhite; }

.location-button {
  background-color: navajowhite !important;
  color: #565656 !important; }

.location-button:hover,
.location-button:active {
  background-color: #ffcd82 !important;
  color: rgba(0, 0, 0, 0.8) !important; }

.localisation-icon {
  width: 100px;
  margin: 0 15px;
  animation: dropdown infinite 3s ease-in-out; }

.localisation-icon-error {
  width: 100px;
  margin: 0 15px; }

@media only screen and (max-width: 768px) {
  .localisation-icon-error > .web {
    display: none; }
  .localisation-icon-error {
    margin: 0; } }

@media only screen and (min-width: 769px) {
  .localisation-icon-error > .mobile {
    display: none; } }

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

/* .underline
 text-decoration: underline
 */
.font-weight {
  font-weight: 500; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-600 {
  font-weight: 600; }

.padding-bottom-0 {
  padding-bottom: 0; }

.padding-b1rem {
  margin-bottom: 1rem !important; }

.padding-top-0 {
  padding-top: 0 !important; }

.padding-h-10 {
  padding: 0 10px; }

.large-text {
  font-size: large; }

.line-height-initial {
  line-height: initial; }

.overflow-hover-x {
  overflow: auto; }

.overflow-hover-x::-webkit-scrollbar {
  display: none; }

.description-text-size {
  font-size: smaller;
  text-align: center; }

@keyframes bounding {
  from {
    top: 17px; }
  50% {
    top: 30px; }
  to {
    top: 17px; } }

.title-description {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 5px 31px;
  background: #3a3a3a;
  color: whitesmoke;
  z-index: 5;
  font-size: medium;
  cursor: pointer;
  animation: bounding 1.5s cubic-bezier(0.4, 0.07, 0.69, 0.96) infinite;
  transition: transform 2.5s ease-in; }

@media only screen and (max-width: 768px) {
  .title-description {
    padding: 0px 8px; } }

.mouse-over {
  cursor: pointer; }

.round {
  border-radius: 50%; }

.dots {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 1em;
  display: flex;
  justify-content: center; }

.dot {
  cursor: pointer;
  margin: 0 3px;
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  background: #80808057; }

.dot.active {
  background: navajowhite; }

.z-index-behind {
  z-index: -1; }

.z-index-standart {
  z-index: 0; }

.z-index-third {
  z-index: 2; }

@media only screen and (max-width: 768px) {
  .overflow-hover {
    overflow: auto; }
  .overflow-hover::-webkit-scrollbar {
    display: none; }
  .slider-size {
    width: 200px; }
  .privacy-container {
    max-height: 20vh;
    overflow-y: scroll;
    text-align: left; } }

@media only screen and (min-width: 768px) {
  .overflow-hover {
    overflow: hidden; }
  .overflow-hover:hover,
  .overflow-hover:active {
    overflow: auto; }
  .overflow-hover::-webkit-scrollbar {
    display: none; }
  .slider-size {
    width: 500px; }
  .privacy-container {
    max-height: 30vh;
    overflow-y: scroll;
    text-align: left; } }

.privacy-container-large {
  max-height: 50vh;
  overflow-y: scroll;
  text-align: left; }

.privacy-container::-webkit-scrollbar {
  display: none; }

.privacy-container:hover::-webkit-scrollbar {
  display: block; }

.privacy-more-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10; }

.text-center {
  text-align: center; }

.text-center-important {
  text-align: center !important; }

/* Home */
.bottom-right-side {
  background: #e5e5f6;
  opacity: 0.9;
  /* background: rgba(78, 84, 200, 0.15) */ }

.width-100 {
  width: 100%; }

.width-100-p {
  width: 100%; }

.grid-normal {
  height: 100%;
  margin: 0 !important;
  width: 100%; }

.column-normal {
  height: 100%;
  padding: 0 !important; }

.row-normal {
  padding: 0 !important;
  width: 100%; }

.infos-container-div {
  display: flex;
  justify-content: space-evenly; }

.ta-left {
  text-align: left; }

.ta-right {
  text-align: right; }

.ta-center {
  text-align: center !important; }

.mh-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.mh-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.mt-5px {
  margin-top: 5px; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-75 {
  margin-top: 75px; }

.mt-100 {
  margin-top: 100px; }

.mt-20 {
  margin-top: 20%; }

.mt-23px {
  margin-top: 23px; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.mb-2em {
  margin-bottom: 2em !important; }

.mb-10 {
  margin-bottom: 10px; }

/* Text formatage and space */
.mr-3 {
  margin-right: 3px; }

.ml-2 {
  margin-left: 2em; }

.ml-3 {
  margin-left: 3em; }

.ml-8 {
  margin-left: 8px; }

.ml-3x {
  margin-left: 3px; }

.ml-5x {
  margin-left: 5px; }

.ml-8-i {
  margin-left: 8px !important; }

.ml-10-i {
  margin-left: 10px !important; }

.ml-15-i {
  margin-left: 15px !important; }

.mt-1 {
  margin-top: 1em; }

.mt-10 {
  margin-top: 10px !important; }

.mt-3em {
  margin-top: 3em; }

.mt-4em {
  margin-top: 4em; }

.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.mv-2px {
  margin-top: 2px;
  margin-bottom: 2px; }

.mv-2 {
  margin-top: 1em;
  margin-bottom: 1em; }

.ph-10 {
  padding: 0 10px !important; }

h4 {
  font-weight: 700; }

.overflow-hidden {
  overflow: hidden; }

.font-xl {
  font-size: x-large !important; }

.font-xl {
  font-size: xx-large !important; }

.font-xxxl {
  font-size: xxx-large !important; }

/* chartist */
.chartist-tooltip {
  border-radius: 5px;
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: .5em;
  background: #4e54c8;
  color: white;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1001;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear; }

/* .chartist-tooltip:before
 content: ""
 position: absolute
 top: 100%
 left: 50%
 width: 0
 height: 0
 margin-left: -15px
 border: 15px solid transparent
 border-top-color:#4e54c8
 */
.chartist-tooltip.tooltip-show {
  opacity: 1; }

.ct-area,
.ct-line {
  pointer-events: none; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central; }

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-grid-background {
  fill: none; }

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 4px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.1; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #d70206; }

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
  fill: #d70206; }

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
  fill: #f05b4f; }

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #f4c63d; }

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-area {
  fill: #f4c63d; }

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #d17905; }

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-area {
  fill: #d17905; }

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #453d3f; }

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-area {
  fill: #453d3f; }

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #59922b; }

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-area {
  fill: #59922b; }

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #0544d3; }

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-area {
  fill: #0544d3; }

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392; }

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-area {
  fill: #6b0392; }

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-area {
  fill: #f05b4f; }

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
  stroke: #dda458; }

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-area {
  fill: #dda458; }

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d; }

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-area {
  fill: #eacf7d; }

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
  stroke: #86797d; }

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-area {
  fill: #86797d; }

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326; }

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-area {
  fill: #b2c326; }

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-area {
  fill: #6188e2; }

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-area {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }

.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%; }

.ct-square:after {
  content: "";
  display: table;
  clear: both; }

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%; }

.ct-minor-second:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%; }

.ct-major-second:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%; }

.ct-minor-third:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%; }

.ct-major-third:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%; }

.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both; }

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%; }

.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both; }

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%; }

.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }

.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%; }

.ct-golden-section:after {
  content: "";
  display: table;
  clear: both; }

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%; }

.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%; }

.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both; }

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%; }

.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%; }

.ct-octave:after {
  content: "";
  display: table;
  clear: both; }

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%; }

.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%; }

.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%; }

.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both; }

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%; }

.ct-double-octave:after {
  content: "";
  display: table;
  clear: both; }

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-chart-donut .ct-label {
  fill: white;
  color: white;
  font-size: 1rem;
  line-height: 1;
  font-weight: 600; }

.rotate-label .ct-label.ct-horizontal.ct-end {
  transform: rotate(37deg);
  margin-left: -10px; }

/*# sourceMappingURL=chartist.css.map */
.survey-card {
  border-radius: 5px;
  margin: 7.5px;
  cursor: pointer; }

.survey-card.unavailable {
  cursor: not-allowed; }

.blur {
  filter: blur(5px); }

.full-height {
  height: 100vh;
  margin: 0 !important;
  overflow: auto; }

.full-height::-webkit-scrollbar {
  display: none; }

.padding-0 {
  padding: 0 !important; }

.padding-10 {
  padding: 10px !important; }

.margin-0 {
  margin: 0 !important; }

.left-side-container {
  display: flex !important;
  flex-flow: column;
  justify-content: space-around; }

.page-name-container {
  flex-flow: row;
  display: flex;
  width: 100%; }

.selected-text {
  font-size: x-large;
  margin-right: 1em;
  font-weight: bold; }

.message-linkeable {
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under; }

.pointer {
  cursor: pointer; }

.text-color {
  color: #aeaeae; }

.fill-color {
  fill: #aeaeae; }

.text-active {
  color: black; }

.container-icon-description {
  display: flex; }

.text-icon-login {
  margin-left: 1em;
  display: inline-block;
  vertical-align: top;
  font-size: medium; }

.margin-bottom-login-icon {
  margin-bottom: 1em; }

.navbar-button-logout {
  width: 147px; }

/*Right Side login */
.top-icon {
  margin-right: 5em;
  margin-top: 2em; }

.bottom-icon {
  margin-left: 5em;
  margin-bottom: 2em; }

.right-side-text-container {
  display: flex !important;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 45%;
  margin-top: 25px;
  text-align: left; }

.right-side-text-container > div {
  margin-left: -45%; }

.right-side-text-container > div .link {
  margin-left: 4px; }

.right-side-text-container > div .right-side-login-link, .right-side-text-container > div .link {
  font-size: smaller;
  color: rgba(255, 255, 255, 0.85);
  font-weight: bold; }

.right-side-text-container > div .right-side-login-link .link, .right-side-text-container > div .right-side-login-link.link:active, .right-side-text-container > div .right-side-login-link.link:hover, .right-side-text-container > div .link .link, .right-side-text-container > div .link.link:active, .right-side-text-container > div .link.link:hover {
  text-decoration: underline;
  outline: none;
  color: white; }

.right-side-login-title {
  font-size: 1.8rem;
  color: white;
  font-weight: 1000; }

@keyframes white-intensity {
  from {
    color: rgba(255, 255, 255, 0.75); }
  50% {
    color: white; }
  to {
    color: rgba(255, 255, 255, 0.75); } }

.right-side-login-text {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.75);
  max-width: 460px;
  line-height: 1.6;
  animation: white-intensity 5s ease-in infinite; }

/* Form */
.ui.pointing.label:before {
  z-index: 0; }

.hidden {
  display: none; }

.question-form-container {
  display: flex;
  align-items: center; }

.form-box {
  border: 2px solid blue;
  margin: 10px; }

.question-form-container .info {
  min-width: 15vw;
  margin-left: 35px;
  position: absolute;
  z-index: 10;
  background: #e5e5f6;
  padding: 8px;
  border-radius: 3px;
  font-weight: 500;
  color: black;
  font-size: small;
  cursor: help; }

.question-form-container .info.top {
  bottom: 140%;
  left: -250%; }

.question-form-container .info.top.max-width {
  max-width: 165px; }

.question-form-container:hover .info {
  display: block; }

.question-form-container .icon {
  cursor: help;
  margin: 0 5px;
  color: #4e54c8; }

.question-form-container .icon:hover {
  opacity: 0.8; }

.title-form {
  color: black;
  font-weight: 500;
  font-size: medium; }

.legend-input {
  font-size: smaller;
  margin-left: 5px;
  text-decoration: underline;
  text-underline-position: under;
  cursor: default; }

.max-height-form-fields {
  overflow-y: auto;
  max-height: 65vh; }

.max-height-form-fields::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.max-height-form-fields::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.max-height-form-fields::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #4e54c8;
  -webkit-box-shadow: inset 0 0 6px rgba(78, 84, 200, 0.3);
  box-shadow: inset 0 0 6px rgba(78, 84, 200, 0.3); }

.overflow-y {
  overflow-y: auto; }

.empty-fields-responsive {
  display: flex;
  overflow-x: auto;
  max-height: 3em; }

/* LogGrid */
.white {
  color: white; }

.max-height {
  height: 100%; }

.left-container-home {
  margin: 0 !important;
  padding: 2em 0 !important;
  text-align: left; }

.mb-item {
  margin-bottom: 1.4em; }

.separator-items {
  width: 10px;
  height: 1px;
  background: white; }

.item-menu {
  opacity: 0.7; }

.item-menu.active {
  opacity: 1;
  font-weight: bold; }

.item-menu:hover {
  opacity: 1;
  font-weight: bold; }

.icon-item {
  vertical-align: text-top; }

.icon-item.right {
  margin-left: 5px; }

.icon-item.left {
  margin-right: 5px; }

.last-item {
  position: absolute;
  bottom: 0;
  margin-bottom: 0; }

/* Grid */
.title-row {
  height: 10%; }

.steps-title {
  position: absolute;
  right: 0;
  align-self: flex-end;
  margin: 0 3em 0 0 !important; }

.half-row {
  min-height: 45%;
  margin-bottom: 10px; }

.eighty-row {
  height: 80%; }

.form {
  text-align: left; }

.form-container {
  display: flex; }

.description-input {
  max-width: 550px; }

.conform-label {
  display: inline-block;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: baseline;
  font-size: .92857143em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: none; }

/* .wrap-form > .field
 display: -webkit-inline-box
 */
.vertical-width-90 {
  margin-left: auto;
  margin-right: auto;
  width: 90% !important;
  max-height: 100%; }

.status-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px; }

/* Offers */
.description-offer {
  padding: 0 14%;
  margin: 2em 0;
  font-size: 0.8rem; }

.vertical-center {
  margin-left: auto;
  margin-right: auto; }

.horizontal-center {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.signet-offer {
  position: absolute;
  top: -9px;
  left: -1px;
  background: #21ba45;
  font-size: smaller;
  padding: 1px 10px;
  color: white;
  border-radius: 3px; }

.bandeau-offer {
  width: 110%;
  position: absolute;
  padding: 2px 10px;
  left: -5%;
  background: blueviolet;
  color: white;
  font-size: smaller;
  border-radius: 3px;
  bottom: 10px;
  max-height: 42px;
  line-height: normal;
  font-weight: 600; }

.legend-offer {
  padding: 0px 10%;
  font-size: smaller;
  margin-top: 1em;
  line-height: normal; }

.segments-container {
  width: 65%;
  max-width: 284px;
  text-align: left; }

.legend-label-price {
  font-size: 0.6rem;
  line-height: 1;
  display: flex;
  flex-flow: column-reverse;
  padding-bottom: 2px; }

.legend-price {
  position: absolute;
  font-size: 0.8rem;
  font-style: italic;
  text-underline-position: under;
  text-decoration: underline; }

/* header */
.header-container {
  position: fixed;
  right: 0;
  z-index: 10;
  top: 0;
  margin: 1em; }

.header-icon-container {
  display: flex;
  flex-flow: row-reverse; }

.header-icon {
  cursor: pointer;
  color: black;
  opacity: 0.6 !important; }

.bell-header-icon {
  cursor: pointer;
  color: #4e54c8; }

.header-icon:hover {
  color: #4e54c8; }

.notification-bell-number {
  position: absolute;
  top: -3px;
  right: 2px;
  font-size: smaller;
  color: #4e54c8; }

.dialog-header-container {
  width: 15vw !important; }

/* position: fixed */
.close-dialog {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5); }

.close-dialog:hover {
  color: black !important; }

.password-meter {
  font-weight: bold;
  height: 7px;
  border-radius: 4px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
  width: 87%;
  background-color: lightgray; }

.strength-password-name {
  font-weight: bold; }

.password-progress-bar-25 {
  width: 25%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: darkred; }

.password-progress-bar-50 {
  width: 50%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: orange; }

.password-progress-bar-75 {
  width: 75%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: yellow; }

.password-progress-bar-75 {
  width: 75%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: yellow; }

.password-progress-bar-100 {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: greenyellow; }

/* Survey Button */
.survey-button-container {
  background: rgba(78, 84, 200, 0.1);
  border: dashed 1px;
  border-radius: 5px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px; }

.survey-button-container:hover {
  background: rgba(78, 84, 200, 0.2);
  cursor: pointer; }

.survey-button-container .header {
  margin-left: 15px; }

.survey-button-container .plus {
  position: absolute;
  right: 3px;
  bottom: 10px; }

.keywords-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.keywords-container .label {
  margin-right: 10px;
  color: #ffffff;
  cursor: pointer;
  color: #565656;
  background-color: rgba(78, 84, 200, 0.1);
  -webkit-transition: background-color 1s;
  transition: background-color 1s; }

.keywords-container .label.focus {
  color: #ffffff;
  background-color: #cb8cff; }

.keywords-container .label:hover {
  color: #ffffff;
  background-color: #cb8cff; }

.keywords-container.materials .label.focus:first-child {
  background-color: #ffaaaa; }

.keywords-container.materials .label:first-child:hover {
  background-color: #ffaaaa; }

.keywords-container.materials .label.focus:nth-child(2) {
  background-color: #fdf3be;
  color: #565656; }

.keywords-container.materials .label:nth-child(2):hover {
  background-color: #fdf3be;
  color: #565656; }

.keywords-container.materials .label.focus:nth-child(3) {
  background-color: thistle; }

.keywords-container.materials .label:nth-child(3):hover {
  background-color: thistle; }

.ui.form .required.field > div > label:after {
  display: inline-block;
  vertical-align: top;
  margin: -.2em 0 0 .2em;
  content: '*';
  color: #db2828; }

.h-fit {
  height: fit-content; }

.preview-image {
  max-height: 50px; }

.ui.form .inline.field > :first-child {
  margin: 0; }

.back-link {
  position: absolute;
  left: 23px;
  font-weight: 1000;
  cursor: pointer;
  opacity: 0.8;
  display: flex;
  margin: 0 !important; }

.back-link.unavailable {
  cursor: not-allowed; }

.back-link label {
  cursor: pointer; }

.back-link:not(.unavailable):hover {
  opacity: 1; }

.icon-back-link {
  margin: 0 !important; }

.icon-back-link:hover {
  opacity: 1; }

.step-text {
  position: absolute;
  color: #4e54c8;
  right: 90px;
  font-weight: 600;
  margin-top: 2px; }

@media screen and (max-width: 1600px) {
  .text-back-link {
    display: none; } }

.next-step-button {
  position: absolute !important;
  right: 5%;
  bottom: 5%; }

.purple-background-button {
  background-color: #4e54c8 !important;
  opacity: 0.8; }

.purple-background-button:hover {
  opacity: 1; }

.sub-subtitle {
  width: 65%; }

.log-grid-container .log-grid-menu-container {
  transition: 1s width; }

.log-grid-container .menu-hide-icon-container {
  z-index: 10;
  position: fixed;
  left: 16vw;
  top: 5px;
  cursor: pointer;
  transition: left 1s, top 1s; }

.log-grid-container .menu-hide-icon-container .ui.toggle.checkbox input:checked ~ .box:before, .log-grid-container .menu-hide-icon-container .ui.toggle.checkbox input:checked ~ label:before {
  background-color: #a979ff !important; }

.log-grid-container .left-container-home .menu-items div {
  opacity: 1;
  transition-property: opacity;
  transition-delay: 1s; }

.log-grid-container.hide-menu .menu-hide-icon-container {
  left: 0vw;
  top: 2vh;
  transition: left 1s, top 1s; }

.log-grid-container.hide-menu .log-grid-right-side-container {
  width: 98vw !important;
  margin-left: 2vw; }

.log-grid-container.hide-menu .log-grid-right-side-container .title-container, .log-grid-container.hide-menu .log-grid-right-side-container .guide-container {
  width: 98vw !important; }

.log-grid-container.hide-menu .log-grid-menu-container {
  width: 2vw !important;
  transition: 1s width; }

.log-grid-container.hide-menu .log-grid-menu-container .left-container-home .menu-items div {
  opacity: 0;
  transition: opacity 0s; }

@keyframes coloredThenOpacity {
  0% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

.notification-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #e0ce2f;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  animation: 5s linear 0s 1 coloredThenOpacity;
  transition: opacity 0.1s linear; }

.notification-container:hover {
  opacity: 1; }

.notification-container.in-progress {
  background-color: #e0ce2f; }

.notification-container.done {
  background-color: #209a0a; }

.notification-container.error {
  background-color: #880000; }

.notification-container.waiting {
  background-color: #e68900; }

.notification-container .top-container {
  display: flex;
  justify-content: space-between;
  padding: 5px; }

.notification-container .top-container i.remove {
  opacity: 0.5; }

.notification-container .top-container i.remove:hover {
  opacity: 1; }

.notification-container .bottom-container {
  padding: 0px 5px 10px 5px; }

.notification-container .bottom-container .description {
  flex-grow: 1;
  text-align: center;
  font-size: small;
  line-height: 1; }

.ui.form.unavailable {
  filter: opacity(0.5); }

.ui.form.unavailable:after {
  content: " ";
  display: block;
  background: rgba(1, 1, 1, 0.2);
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  cursor: not-allowed; }

.limit-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  animation: appears forwards 1s linear; }

.limit-container .upgrade-button-container {
  display: inline-block;
  position: relative; }

.limit-container .upgrade-button-container button {
  width: 365px; }

.limit-container .upgrade-button-container svg {
  position: absolute;
  right: -5%;
  top: 14%; }

.limit-container .limit {
  color: white;
  background-color: #4e54c8;
  border-radius: 15px;
  padding: 15px; }

.limit-container .overlay-icon {
  position: relative; }

.form-error-ul li:before {
  color: #9f3a38 !important; }

.modal-login-container {
  width: 267px !important; }

.modal-login-container div {
  width: auto;
  margin: auto; }

.modal-login-container .modal-login-button {
  width: 100%;
  background-color: #5829bb;
  color: white; }

.google_login_icon {
  height: 18px;
  width: 18px;
  margin-right: 12px; }

.google-login-container {
  background-color: #fff;
  border: 2px solid #4285f4;
  color: #4285f4;
  display: flex;
  padding: 0;
  font-size: 18px;
  font-weight: 900;
  height: 44px;
  width: 100%;
  max-width: 100%;
  user-select: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  position: relative;
  justify-content: center;
  text-align: center;
  white-space: nowrap; }

.google-login-container:hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); }

.menu-container {
  display: flex !important;
  flex-direction: column;
  width: 100% !important;
  height: 100%; }

.menu-container .notifications-container {
  align-self: center;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  margin: 0 5px;
  max-width: fit-content;
  scrollbar-width: thin; }

.menu-container .notifications-container > div {
  margin-bottom: 5px; }

.menu-container .notifications-container > div:last-child {
  margin-bottom: 0; }

.menu-container .notifications-container::-webkit-scrollbar {
  display: none; }

.width-1 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 1% !important;
  max-height: 100%; }

.width-2 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 2% !important;
  max-height: 100%; }

.width-3 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 3% !important;
  max-height: 100%; }

.width-4 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 4% !important;
  max-height: 100%; }

.width-5 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 5% !important;
  max-height: 100%; }

.width-6 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 6% !important;
  max-height: 100%; }

.width-7 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 7% !important;
  max-height: 100%; }

.width-8 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 8% !important;
  max-height: 100%; }

.width-9 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 9% !important;
  max-height: 100%; }

.width-10 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 10% !important;
  max-height: 100%; }

.width-11 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 11% !important;
  max-height: 100%; }

.width-12 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 12% !important;
  max-height: 100%; }

.width-13 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 13% !important;
  max-height: 100%; }

.width-14 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 14% !important;
  max-height: 100%; }

.width-15 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 15% !important;
  max-height: 100%; }

.width-16 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 16% !important;
  max-height: 100%; }

.width-17 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 17% !important;
  max-height: 100%; }

.width-18 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 18% !important;
  max-height: 100%; }

.width-19 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 19% !important;
  max-height: 100%; }

.width-20 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 20% !important;
  max-height: 100%; }

.width-21 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 21% !important;
  max-height: 100%; }

.width-22 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 22% !important;
  max-height: 100%; }

.width-23 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 23% !important;
  max-height: 100%; }

.width-24 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 24% !important;
  max-height: 100%; }

.width-25 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 25% !important;
  max-height: 100%; }

.width-26 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 26% !important;
  max-height: 100%; }

.width-27 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 27% !important;
  max-height: 100%; }

.width-28 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 28% !important;
  max-height: 100%; }

.width-29 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 29% !important;
  max-height: 100%; }

.width-30 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 30% !important;
  max-height: 100%; }

.width-31 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 31% !important;
  max-height: 100%; }

.width-32 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 32% !important;
  max-height: 100%; }

.width-33 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 33% !important;
  max-height: 100%; }

.width-34 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 34% !important;
  max-height: 100%; }

.width-35 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 35% !important;
  max-height: 100%; }

.width-36 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 36% !important;
  max-height: 100%; }

.width-37 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 37% !important;
  max-height: 100%; }

.width-38 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 38% !important;
  max-height: 100%; }

.width-39 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 39% !important;
  max-height: 100%; }

.width-40 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 40% !important;
  max-height: 100%; }

.width-41 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 41% !important;
  max-height: 100%; }

.width-42 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 42% !important;
  max-height: 100%; }

.width-43 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 43% !important;
  max-height: 100%; }

.width-44 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 44% !important;
  max-height: 100%; }

.width-45 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 45% !important;
  max-height: 100%; }

.width-46 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 46% !important;
  max-height: 100%; }

.width-47 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 47% !important;
  max-height: 100%; }

.width-48 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 48% !important;
  max-height: 100%; }

.width-49 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 49% !important;
  max-height: 100%; }

.width-50 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 50% !important;
  max-height: 100%; }

.width-51 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 51% !important;
  max-height: 100%; }

.width-52 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 52% !important;
  max-height: 100%; }

.width-53 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 53% !important;
  max-height: 100%; }

.width-54 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 54% !important;
  max-height: 100%; }

.width-55 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 55% !important;
  max-height: 100%; }

.width-56 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 56% !important;
  max-height: 100%; }

.width-57 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 57% !important;
  max-height: 100%; }

.width-58 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 58% !important;
  max-height: 100%; }

.width-59 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 59% !important;
  max-height: 100%; }

.width-60 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 60% !important;
  max-height: 100%; }

.width-61 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 61% !important;
  max-height: 100%; }

.width-62 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 62% !important;
  max-height: 100%; }

.width-63 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 63% !important;
  max-height: 100%; }

.width-64 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 64% !important;
  max-height: 100%; }

.width-65 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 65% !important;
  max-height: 100%; }

.width-66 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 66% !important;
  max-height: 100%; }

.width-67 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 67% !important;
  max-height: 100%; }

.width-68 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 68% !important;
  max-height: 100%; }

.width-69 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 69% !important;
  max-height: 100%; }

.width-70 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 70% !important;
  max-height: 100%; }

.width-71 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 71% !important;
  max-height: 100%; }

.width-72 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 72% !important;
  max-height: 100%; }

.width-73 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 73% !important;
  max-height: 100%; }

.width-74 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 74% !important;
  max-height: 100%; }

.width-75 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 75% !important;
  max-height: 100%; }

.width-76 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 76% !important;
  max-height: 100%; }

.width-77 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 77% !important;
  max-height: 100%; }

.width-78 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 78% !important;
  max-height: 100%; }

.width-79 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 79% !important;
  max-height: 100%; }

.width-80 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 80% !important;
  max-height: 100%; }

.width-81 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 81% !important;
  max-height: 100%; }

.width-82 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 82% !important;
  max-height: 100%; }

.width-83 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 83% !important;
  max-height: 100%; }

.width-84 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 84% !important;
  max-height: 100%; }

.width-85 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 85% !important;
  max-height: 100%; }

.width-86 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 86% !important;
  max-height: 100%; }

.width-87 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 87% !important;
  max-height: 100%; }

.width-88 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 88% !important;
  max-height: 100%; }

.width-89 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 89% !important;
  max-height: 100%; }

.width-90 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 90% !important;
  max-height: 100%; }

.width-91 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 91% !important;
  max-height: 100%; }

.width-92 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 92% !important;
  max-height: 100%; }

.width-93 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 93% !important;
  max-height: 100%; }

.width-94 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 94% !important;
  max-height: 100%; }

.width-95 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 95% !important;
  max-height: 100%; }

.width-96 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 96% !important;
  max-height: 100%; }

.width-97 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 97% !important;
  max-height: 100%; }

.width-98 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 98% !important;
  max-height: 100%; }

.width-99 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 99% !important;
  max-height: 100%; }

.width-100 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
  max-height: 100%; }

@media only screen and (max-width: 950px) {
  .width-1 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-2 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-3 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-4 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-5 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-6 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-7 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-8 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-9 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-10 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-11 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-12 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-13 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-14 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-15 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-16 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-17 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-18 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-19 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-20 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-21 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-22 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-23 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-24 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-25 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-26 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-27 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-28 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-29 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-30 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-31 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-32 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-33 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-34 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-35 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-36 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-37 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-38 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-39 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-40 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-41 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-42 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-43 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-44 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-45 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-46 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-47 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-48 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-49 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-50 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-51 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-52 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-53 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-54 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-55 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-56 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-57 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-58 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-59 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-60 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-61 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-62 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-63 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-64 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-65 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-66 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-67 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-68 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-69 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-70 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-71 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-72 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-73 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-74 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-75 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-76 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-77 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-78 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-79 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-80 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-81 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-82 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-83 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-84 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-85 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-86 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-87 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-88 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-89 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-90 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-91 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-92 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-93 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-94 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-95 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-96 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-97 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-98 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-99 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; }
  .width-100 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 95% !important;
    max-height: 100%; } }

.log-grid-menu-container {
  position: fixed !important;
  height: 100vh;
  width: 19vw !important;
  /* margin-left: 19vw */ }

.log-grid-right-side-container {
  width: 81vw !important;
  margin-left: 19vw; }

.padding-3-vh {
  padding: 3vh 2px; }

.padding-1-5-vh {
  padding: 1.5vh 2px; }

.guide-container {
  position: fixed;
  bottom: 0;
  width: 81vw !important;
  /* height: 10vh */ }

.ui.grid > .row.guide-container {
  width: 81vw !important; }

.right-side-container {
  width: 100%; }

.right-side-container.guide {
  margin-bottom: 20vh; }

/* title */
.title-container {
  z-index: 9;
  top: 0;
  padding-top: 10px;
  width: 81vw;
  /* position: fixed --> make system to put fixed on move (when div top !== 0 || slide to down) */ }

.title-title {
  display: inline-block; }

.title-container .status-container {
  margin-left: 15px; }

.title-container .icon-status-container {
  padding-top: 4px; }

.title-container .status-container .header {
  margin-top: 0;
  margin-bottom: 10px; }

.title-container .responses-status {
  padding: 8px;
  border-radius: 9px;
  font-weight: 600;
  margin-left: 15px;
  color: black;
  margin-bottom: auto; }

/* page */
.page-container {
  padding-top: 3em;
  width: 100%;
  position: relative; }

/* profile */
.profile-action-container {
  margin-top: 75px; }

.profile-infos-container {
  margin-left: 20%;
  margin-top: 80px; }

/* support */
.support-subtitle-container {
  margin-top: 2em; }

.subtitle {
  font-size: 1rem;
  margin-bottom: 5px; }

.support.cards-container {
  margin-top: 5em;
  display: flex; }

.card-container.not-first {
  margin-left: 6em; }

.support .card-content {
  padding: 1em 2em;
  border-radius: 5px;
  display: inline-block; }

.card-content .title-infos {
  margin-bottom: 40px; }

.card-content .sub-infos {
  margin: 0 0 10px 0;
  font-weight: 500; }

.italic {
  font-style: italic; }

/* si bug verifier ici */
.ui.grid > * {
  padding-left: 0;
  padding-right: 0; }

.height-400 {
  max-height: 400px;
  overflow: auto; }
